import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["instantSearchInstance", "widgetParams"],
    _excluded2 = ["widgetParams"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMemo, useRef, useState } from 'react';
import { dequal } from "../lib/dequal.js";
import { getIndexSearchResults } from "../lib/getIndexSearchResults.js";
import { useIndexContext } from "../lib/useIndexContext.js";
import { useInstantSearchContext } from "../lib/useInstantSearchContext.js";
import { useInstantSearchServerContext } from "../lib/useInstantSearchServerContext.js";
import { useStableValue } from "../lib/useStableValue.js";
import { useWidget } from "../lib/useWidget.js";
export function useConnector(connector) {
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var additionalWidgetProperties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var serverContext = useInstantSearchServerContext();
  var search = useInstantSearchContext();
  var parentIndex = useIndexContext();
  var stableProps = useStableValue(props);
  var stableAdditionalWidgetProperties = useStableValue(additionalWidgetProperties);
  var shouldSetStateRef = useRef(true);
  var previousRenderStateRef = useRef(null);
  var widget = useMemo(function () {
    var createWidget = connector(function (connectorState, isFirstRender) {
      // We skip the `init` widget render because:
      // - We rely on `getWidgetRenderState` to compute the initial state before
      //   the InstantSearch.js lifecycle starts.
      // - It prevents UI flashes when updating the widget props.
      if (isFirstRender) {
        shouldSetStateRef.current = true;
        return;
      } // There are situations where InstantSearch.js may render widgets slightly
      // after they're removed by React, and thus try to update the React state
      // on unmounted components. React 16 and 17 consider them as memory leaks
      // and display a warning.
      // This happens in <DynamicWidgets> when `attributesToRender` contains a
      // value without an attribute previously mounted. React will unmount the
      // component controlled by that attribute, but InstantSearch.js will stay
      // unaware of this change until the render pass finishes, and therefore
      // notifies of a state change.
      // This ref lets us track this situation and ignore these state updates.


      if (shouldSetStateRef.current) {
        var instantSearchInstance = connectorState.instantSearchInstance,
            widgetParams = connectorState.widgetParams,
            renderState = _objectWithoutProperties(connectorState, _excluded); // We only update the state when a widget render state param changes,
        // except for functions. We ignore function reference changes to avoid
        // infinite loops. It's safe to omit them because they get updated
        // every time another render param changes.


        if (!dequal(renderState, previousRenderStateRef.current, function (a, b) {
          return (a === null || a === void 0 ? void 0 : a.constructor) === Function && (b === null || b === void 0 ? void 0 : b.constructor) === Function;
        })) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          setState(renderState);
          previousRenderStateRef.current = renderState;
        }
      }
    }, function () {
      // We'll ignore the next state update until we know for sure that
      // InstantSearch.js re-inits the component.
      shouldSetStateRef.current = false;
    });
    return _objectSpread(_objectSpread({}, createWidget(stableProps)), stableAdditionalWidgetProperties);
  }, [connector, stableProps, stableAdditionalWidgetProperties]);

  var _useState = useState(function () {
    if (widget.getWidgetRenderState) {
      var _widget$getWidgetSear;

      // The helper exists because we've started InstantSearch.
      var helper = parentIndex.getHelper();
      var uiState = parentIndex.getWidgetUiState({})[parentIndex.getIndexId()];
      helper.state = ((_widget$getWidgetSear = widget.getWidgetSearchParameters) === null || _widget$getWidgetSear === void 0 ? void 0 : _widget$getWidgetSear.call(widget, helper.state, {
        uiState: uiState
      })) || helper.state;

      var _getIndexSearchResult = getIndexSearchResults(parentIndex),
          results = _getIndexSearchResult.results,
          scopedResults = _getIndexSearchResult.scopedResults; // We get the widget render state by providing the same parameters as
      // InstantSearch provides to the widget's `render` method.
      // See https://github.com/algolia/instantsearch.js/blob/019cd18d0de6dd320284aa4890541b7fe2198c65/src/widgets/index/index.ts#L604-L617


      // We get the widget render state by providing the same parameters as
      // InstantSearch provides to the widget's `render` method.
      // See https://github.com/algolia/instantsearch.js/blob/019cd18d0de6dd320284aa4890541b7fe2198c65/src/widgets/index/index.ts#L604-L617
      var _widget$getWidgetRend = widget.getWidgetRenderState({
        helper: helper,
        parent: parentIndex,
        instantSearchInstance: search,
        results: results,
        scopedResults: scopedResults,
        state: helper.state,
        renderState: search.renderState,
        templatesConfig: search.templatesConfig,
        createURL: parentIndex.createURL,
        searchMetadata: {
          isSearchStalled: search._isSearchStalled
        }
      }),
          widgetParams = _widget$getWidgetRend.widgetParams,
          renderState = _objectWithoutProperties(_widget$getWidgetRend, _excluded2);

      return renderState;
    }

    return {};
  }),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  useWidget({
    widget: widget,
    parentIndex: parentIndex,
    props: stableProps,
    shouldSsr: Boolean(serverContext)
  });
  return state;
}