/**
 * Throws an error if the condition is not met.
 *
 * The error is exhaustive in development, and becomes generic in production.
 *
 * This is used to make development a better experience to provide guidance as
 * to where the error comes from.
 */
export function invariant(condition, message) {
  if (condition) {
    return;
  }

  if (!(process.env.NODE_ENV !== 'production')) {
    throw new Error('Invariant failed');
  }

  if (process.env.NODE_ENV !== 'production') {
    throw new Error("[InstantSearch] ".concat(typeof message === 'function' ? message() : message));
  }
}