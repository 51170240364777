import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useEffect, useState } from 'react';
import { useIndexContext } from "./useIndexContext.js";
import { useInstantSearchContext } from "./useInstantSearchContext.js";
export function useSearchState() {
  var search = useInstantSearchContext();
  var searchIndex = useIndexContext();
  var indexId = searchIndex.getIndexId();

  var _useState = useState(function () {
    return search.getUiState();
  }),
      _useState2 = _slicedToArray(_useState, 2),
      uiState = _useState2[0],
      setLocalUiState = _useState2[1];

  var indexUiState = uiState[indexId];
  var setUiState = useCallback(function (nextUiState) {
    search.setUiState(nextUiState);
  }, [search]);
  var setIndexUiState = useCallback(function (nextUiState) {
    setUiState(function (prevUiState) {
      return _objectSpread(_objectSpread({}, prevUiState), {}, _defineProperty({}, indexId, typeof nextUiState === 'function' ? nextUiState(prevUiState[indexId]) : nextUiState));
    });
  }, [setUiState, indexId]);
  useEffect(function () {
    function handleRender() {
      setLocalUiState(search.getUiState());
    }

    search.addListener('render', handleRender);
    return function () {
      search.removeListener('render', handleRender);
    };
  }, [search, indexId]);
  return {
    uiState: uiState,
    setUiState: setUiState,
    indexUiState: indexUiState,
    setIndexUiState: setIndexUiState
  };
}