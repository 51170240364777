import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useEffect, useState } from 'react';
import { getIndexSearchResults } from "./getIndexSearchResults.js";
import { useIndexContext } from "./useIndexContext.js";
import { useInstantSearchContext } from "./useInstantSearchContext.js";
export function useSearchResults() {
  var search = useInstantSearchContext();
  var searchIndex = useIndexContext();

  var _useState = useState(function () {
    return getIndexSearchResults(searchIndex);
  }),
      _useState2 = _slicedToArray(_useState, 2),
      searchResults = _useState2[0],
      setSearchResults = _useState2[1];

  useEffect(function () {
    function handleRender() {
      setSearchResults({
        results: searchIndex.getResults(),
        // Results can't be `null` after the first render.
        scopedResults: searchIndex.getScopedResults()
      });
    }

    search.addListener('render', handleRender);
    return function () {
      search.removeListener('render', handleRender);
    };
  }, [search, searchIndex]);
  return searchResults;
}